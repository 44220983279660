export const formatPageTitle = (unformatedTitle) => {
  let pageTitle = unformatedTitle.toLowerCase().replace(/[^\w\s]/gi, '')
      pageTitle = pageTitle.replaceAll(" ", "-");;

  return pageTitle;
}

export const isTouchDevice = () => {
  if (typeof window !== "undefined") {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
  }
  return false;
}