import React, { useEffect, useState } from 'react';
import { browserName } from 'react-device-detect';
import { useAnimate, stagger, motion } from "framer-motion"

import './WorkNavigation.scss'

import ProjectNavigationItem from '../ProjectNavigationItem/ProjectNavigationItem';

// Images for Navigation Menu Props
import DesignImage from "../../images/HomepageProjectNavigation/design.png";
import PhotoImage from "../../images/HomepageProjectNavigation/photo.png";
import FilmImage from "../../images/HomepageProjectNavigation/film.png";
import SpacesImage from "../../images/HomepageProjectNavigation/spaces_2.png";
import ArchiveImage from "../../images/HomepageProjectNavigation/archive.png";

const WorkNavigation = (props) => {

  const [scope, animate] = useAnimate();

  const indexExitAnimation = () => {
    animate(
      '.WorkNavigation--Homepage > .ProjectNavigationItem',
      {
        opacity: 0
      },
      {
        // type: "spring",
        duration: 0.3,
        delay: stagger(0.05)
      }
    )
  }

  const workNavPageAnimation = () => {

    animate(
      '.WorkNavigation--Work > .ProjectNavigationItem',
      {
        opacity: 0.3
      },
      {
        duration: 0.3,
        delay: stagger(0.05, { startDelay: 0.3 })
      }
    )

    animate(
      '.WorkNavigation--Work > .ProjectNavigationItem--active',
      {
        opacity: 1
      },
      {
        duration: 0.3,
      }
    )

  }

  const workNavExitAnimation = () => {
    animate(
      '.WorkNavigation--Work > .ProjectNavigationItem',
      {
        opacity: 0
      },
      {
        // type: "spring",
        duration: 0.3,
        delay: stagger(0.05)
      }
    )
  }

  useEffect(() => {
    if (props.page === "index") {
      // Animation in for homepage
      animate(
        '.WorkNavigation--Homepage > .ProjectNavigationItem',
        {
          opacity: 1
        },
        {
          // type: "spring",
          duration: 0.3,
          delay: stagger(0.05, { startDelay: 0.3 })
        }
      )
    } else {
      // Animation in for work pages navigation
      animate(
        '.WorkNavigation--Work > .ProjectNavigationItem',
        {
          opacity: 0.3
        },
        {
          duration: 0.3,
          delay: stagger(0.05, { startDelay: 0.3 })
        }
      );

      setTimeout(() => {
        animate(
          '.WorkNavigation--Work > .ProjectNavigationItem--active',
          {
            opacity: 1
          },
          {
            duration: 0.3,
          }
        )
      }, 850)
    }
  }, [])
  

if (props.page === "index") {
  return (
    <motion.div
      className={browserName === "Safari" ? `WorkNavigation--Homepage WorkNavigation--Homepage--Safari` : `WorkNavigation--Homepage`}
      ref={scope}
      key="work-nav-homepage">
      <ProjectNavigationItem pageURL="/design" image={DesignImage} pageTitle="Design" active={props.page === "design"} exitAnimation={indexExitAnimation} />
      <ProjectNavigationItem pageURL="/photo" image={PhotoImage} pageTitle="Photo" active={props.page === "photo"} exitAnimation={indexExitAnimation} />
      <ProjectNavigationItem pageURL="/film" image={FilmImage} pageTitle="Film" active={props.page === "film"} exitAnimation={indexExitAnimation} />
      <ProjectNavigationItem pageURL="/spaces" image={SpacesImage} pageTitle="Spaces" active={props.page === "spaces"} exitAnimation={indexExitAnimation} />
      <ProjectNavigationItem pageURL="/archive" image={ArchiveImage} pageTitle="Archive" active={props.page === "archive"} exitAnimation={indexExitAnimation} />
    </motion.div>
  )
} else {
  return (
    <motion.div
      className={browserName === "Safari" ? `WorkNavigation--Work WorkNavigation--Work--Safari` : `WorkNavigation--Work`}
      ref={scope}
      key="work-nav"
    >
      <ProjectNavigationItem className="hoverable" pageURL="/design" image={DesignImage} pageTitle="Design" active={props.page === "design"} exitAnimation={workNavPageAnimation} />
      <ProjectNavigationItem className="hoverable" pageURL="/photo" image={PhotoImage} pageTitle="Photo" active={false} exitAnimation={workNavExitAnimation} />
      <ProjectNavigationItem className="hoverable" pageURL="/film" image={FilmImage} pageTitle="Film" active={false} exitAnimation={workNavExitAnimation} />
      <ProjectNavigationItem className="hoverable" pageURL="/spaces" image={SpacesImage} pageTitle="Spaces" active={props.page === "spaces"} exitAnimation={workNavPageAnimation} />
      <ProjectNavigationItem className="hoverable" pageURL="/archive" image={ArchiveImage} pageTitle="Archive" active={props.page === "archive"} exitAnimation={workNavPageAnimation} />
    </motion.div>
  )
}
  
}

export default WorkNavigation;