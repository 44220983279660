import * as React from 'react';
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';


import './ProjectNavigationItem.scss'

const ProjectNavigationItem = (props) => {
  const mobileBreakpoint = 680;

  if (typeof window !== "undefined") {
    if (props.pageURL === "/film" || props.pageURL === "/photo") {
      return (
        <TransitionLink 
          to={props.pageURL}
          className={props.active ? 'ProjectNavigationItem--active' : 'ProjectNavigationItem'} 
          id={`ProjectNavigationItem--${props.pageTitle}`}
          exit={{
            trigger: ({ exit, node }) => props.exitAnimation(),
            length: 1.2
          }}
          entry={{
            delay: 1.2
          }}
          >
          <div className="ProjectNavigationImage hoverable">
            <div style={{backgroundImage: `url(${props.image})`}} ></div>
          </div>
          <p>{props.pageTitle}</p>
        </TransitionLink>
      )
    } else {
      return (
        <TransitionLink
          to={props.pageURL}
          className={props.active ? 'ProjectNavigationItem ProjectNavigationItem--active' : 'ProjectNavigationItem'}
          id={`ProjectNavigationItem--${props.pageTitle}`}
          exit={{
            trigger: ({ exit, node }) => props.exitAnimation(),
            length: 1.2
          }}
          entry={{
            delay: 1.2
          }}>
          <div className="ProjectNavigationImage hoverable">
            <div style={{backgroundImage: `url(${props.image})`}} ></div>
          </div>
          <p>{props.pageTitle}</p>
        </TransitionLink>
      )
    }
  } else return;
}

export default ProjectNavigationItem;