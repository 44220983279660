import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './src/components/Layout/Layout'

export const wrapPageElement = ({ element }) => (


  <AnimatePresence mode='wait'>
    <Layout>
      {element}
    </Layout>
  </AnimatePresence>
);