import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import './Cursor.scss';

const Cursor = (props) => {


  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);


  useEffect(() => {
    addEventListeners();
       handleLinkHoverEvents();
    return () => removeEventListeners();
  });


  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseenter", onMouseEnter);
    document.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (e) => {
    props.setCursorPosition({ x: e.clientX - 16, y: e.clientY - 16 });
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  }; 

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const handleLinkHoverEvents = () => {
        document.querySelectorAll(".hoverable").forEach(el => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
        });
    };

  return (
    <div className={`cursor ${hidden ? 'cursor--hidden ' : ''}${clicked ? 'cursor--clicked ' : ''}${linkHovered ? 'cursor--link-hovered ' : ''}`} style={{
      left: props.cursorPosition.x,
      top: props.cursorPosition.y
    }}>
      <svg height="30" width="30">
        <circle cx="15" cy="15" r="8" strokeWidth="0"></circle>
      </svg>
    </div>
  )
};

export default Cursor;
