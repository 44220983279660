import React, { useState, useEffect } from 'react'

import { isTouchDevice } from '../../hooks/utils';


// Components
import Cursor from '../Cursor/Cursor';
import Nav from '../Nav/Nav';
import LegalFooter from '../LegalFooter/LegalFooter';
import WorkNavigation from '../WorkNavigation/WorkNavigation';

const Layout = ({ children }) => {

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    const currentPage = url.toLowerCase().replaceAll('/', '')

    const [isWorkPage, setIsWorkPage] = useState(false);

    useEffect(() => {
        if (currentPage === "design" || currentPage === "spaces" || currentPage === "archive") {
            setIsWorkPage(true)
        } else {
            setIsWorkPage(false)
        }
    })

    return (
        <div style={{ background: "#fff" }}>
            {!isTouchDevice() &&
                <Cursor cursorPosition={cursorPosition} setCursorPosition={setCursorPosition} page={currentPage} />
            }
            <Nav />
            <div className="wrapper">
                {isWorkPage &&
                    <WorkNavigation page={currentPage} />
                }
                {children}
            </div>
            <LegalFooter />
        </div>
    )
}

export default Layout;