import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';
import './Nav.scss'
import LegalFooter from '../LegalFooter/LegalFooter';

import { useAnimate, stagger, motion } from "framer-motion";

import Logo from '../../images/KY_logopsd_FARSI_update--min.png';
import JoaquinaLogo from '../../images/JOAQUINA_LOGO-min.png';

const Nav = (props) => {

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)

  const [navOpen, toggleNav] = useState(false);
  
  if (typeof window !== "undefined") {
    return (
      <>
      <div className="nav">
        <div>
          <TransitionLink 
            className="nav-logo hoverable" 
            to="/"
            >
              <img src={Logo} />
          </TransitionLink>
        </div>

        
        { window.location.pathname.includes("/info")  &&
        <div className="desktop-nav-links">
          <p 
            className="hoverable" 
            onClick={ () => {
              gsap.to(window, { duration: 0.6, scrollTo: 0, ease: "power2" })
            }}>Info</p>
          <p 
            className="hoverable"
            onClick={ () => {
              gsap.to(window, { duration: 0.6, scrollTo: {y: "#press", offsetY: 76}, ease: "power2" })
            }
            } 
            >Press</p>
          <p 
            className="hoverable"
            onClick={ () => {
              gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" })
            }}>Contact</p>
        </div>
        }

  { ! window.location.pathname.includes("/info")  &&
        <div className="desktop-nav-links">
          <TransitionLink 
            className="hoverable" 
            to="/info"
            >Info</TransitionLink>
          <TransitionLink 
            className="hoverable" 
            to="/info"
            entry={{
              trigger: ({ exit, node }) => {
                gsap.to(window, { duration: 0.6, scrollTo: {y: "#press", offsetY: 76}, ease: "power2" }).delay(0.4)
              }
            }}
            >Press</TransitionLink>
          <TransitionLink 
            className="hoverable" 
            to="/info"
            entry={{
              trigger: ({ exit, node }) => {
                gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" }).delay(0.4)
              }
            }}>Contact</TransitionLink>
        </div>
        }

        <div className="align-right desktop-nav-links">
          <TransitionLink 
            className="hoverable" 
            to="/"
            >Work</TransitionLink>
          <a className="hoverable joaquina-link" href="https://joaquina.world/" target="_blank" rel="noreferrer">
            <img src={JoaquinaLogo} />
          </a>
        </div>
        <div className="mobile-menu-toggle">
          {!navOpen &&
            <p className="open" onClick={() => toggleNav(true)}>[menu]</p>
          }
          {navOpen &&
            <p className="close" onClick={() => toggleNav(false)}>[close]</p>
          }
        </div>
        </div>
        {navOpen && 
        <div className="mobile-menu">
          <div className="mobile-menu-links">
          { ! window.location.pathname.includes("/info")  &&
            <div className="info-menu-links">
              <TransitionLink 
                to="/info"
                exit={{
                  length: 1.2,
                  trigger: ({ exit, node }) => {
                    toggleNav(false);
                    gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" }).delay(0.4)
                  }
                }}
                entry={{
                  delay: 1.2
                }}
                >Info</TransitionLink>
              <TransitionLink 
                entry={{
                  trigger: ({ exit, node }) => {
                    toggleNav(false);
                    gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" }).delay(0.4)
                  }
                }}
              to="/info">Press</TransitionLink>
              <TransitionLink 
                entry={{
                  trigger: ({ exit, node }) => {
                    toggleNav(false);
                    gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" }).delay(0.4)
                  }
                }}
                to="/info">Contact</TransitionLink>
            </div>
            }
            { window.location.pathname.includes("/info")  &&
              <div className="info-menu-links">
              <p 
                onClick={ () => {
                  toggleNav(false);
                  gsap.to(window, { duration: 0.6, scrollTo: 0, ease: "power2" }).delay(0.2)
                }}>Info</p>
              <p 
                onClick={ () => {
                  toggleNav(false);
                  gsap.to(window, { duration: 0.6, scrollTo: {y: "#press", offsetY: 76}, ease: "power2" }).delay(0.2)
                }
                } 
                >Press</p>
              <p 
                onClick={ () => {
                  toggleNav(false);
                  gsap.to(window, { duration: 0.6, scrollTo: {y: "#contact", offsetY: 76}, ease: "power2" }).delay(0.2)
                }}>Contact</p>
              </div>
            }
            <div className="work-menu-links">
              <TransitionLink 
                to="/"
                entry={{
                  trigger: ({ exit, node }) => {
                    toggleNav(false);
                  },
                }}
                >Work</TransitionLink>
              <a href="https://joaquina.world/" target="_blank" rel="noreferrer">Joaquina</a>
            </div>
          </div>
          <LegalFooter context="mobile" />
        </div>
        }
      </>
    )
  }
}

export default Nav;