import React from 'react';
import './LegalFooter.scss';

const LegalFooter = props => {
  
  switch (props.context) {
    case "projects": {
      return (
        <div className="legal-footer--projects">
          <p>© 2024 Keavan Yazdani. All Rights Reserved.</p>
        </div>
      )
    }
    case "info": {
      return (
        <div className="legal-footer--info">
          <p>© 2024 Keavan Yazdani. All Rights Reserved.</p>
        </div>
      )
    }
    case "mobile": {
      return (
        <div className="legal-footer--mobile">
          <p>© 2024 Keavan Yazdani. All Rights Reserved.</p>
        </div>
      )
    }
    default: {
      return (
        <div className="legal-footer">
          <p>© 2024 Keavan Yazdani. All Rights Reserved.</p>
        </div>
      )
    }
  }
}

export default LegalFooter;